import { css } from '@emotion/react'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'li'> {
  data: Queries.AcademicCalendarEventFragment | null
  oddEven?: 'ODD' | 'EVEN'
}

export const AcademicCalendarEvent = ({
  data,
  oddEven,
  ...props
}: Props): JSX.Element => {
  const eventDate = new Date((data?.date || '') + 'T00:00:00')
  const day = eventDate && format(eventDate, 'd')
  const getDayOfWeek = (date: Date | null | undefined) => {
    if (date) {
      const dayOfWeek = format(date, 'EEEEEE')
      switch (dayOfWeek) {
        case 'Mo':
          return 'M'
        case 'We':
          return 'W'
        case 'Fr':
          return 'F'
        default:
          return dayOfWeek
      }
    }
  }
  const endDate = new Date((data?.endDate || '') + 'T00:00:00')
  const endDay = data?.multiDay && data.endDate && format(endDate, 'd')

  const styles = {
    event: css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 0.5em;
      padding: 1.5em 0 1.67em;
      ${oddEven === 'ODD' &&
      css`
        background: ${colors.gray95};
      `}
      ${oddEven === 'EVEN' &&
      css`
        background: ${colors.gray98};
      `}
      ${mq().s} {
        grid-column-gap: 0;
      }
    `,
    date: css`
      display: grid;
      align-self: flex-start;
      align-content: center;
      justify-items: center;
      font-size: var(--fs-48);
      width: 2.5em;
      padding: 0 0.25em;
      box-sizing: border-box;
      ${mq().s} {
        width: 2em;
        padding-top: 0.075em;
        grid-row: 1 / 4;
      }
    `,
    day: css`
      display: block;
      font-family: var(--ff-display);
      line-height: 1;
      margin: 0;
      ${endDay &&
      css`
        font-size: 75%;
      `}
    `,
    dayOfWeek: css`
      display: block;
      font-size: var(--fs-16);
      font-weight: 350;
      color: ${colors.gray65};
      ${mq().s} {
        font-size: var(--fs-16);
      }
    `,
    info: css`
      padding-right: 1.5em;
      align-self: center;
      ${mq().s} {
        align-self: flex-start;
      }
    `,
    title: css`
      font-size: var(--fs-24);
      font-weight: 350;
      margin: 0.125em 0 0.333em;
    `,
    descriptor: css`
      font-size: var(--fs-18);
      padding-right: 1.5em;
      margin: 0.275em 0;
      font-weight: 350;
      color: ${colors.gray45};
      font-style: italic;
      align-self: center;
      ${mq().s} {
        grid-row: 2 / 3;
        margin-top: 0;
        font-size: var(--fs-16);
      }
    `,
  }
  return (
    <li
      css={styles.event}
      {...props}
    >
      <div css={styles.date}>
        <span css={styles.day}>
          {day}
          {endDay && `-${endDay}`}
        </span>
        <span css={styles.dayOfWeek}>
          {getDayOfWeek(eventDate)}
          {endDay && '-' + getDayOfWeek(endDate)}
        </span>
      </div>
      <div css={styles.info}>
        <h4 css={styles.title}>
          <span>{data?.eventName}</span>
        </h4>
      </div>
      <div css={styles.descriptor}>{data?.descriptor}</div>
    </li>
  )
}

export const AcademicCalendarEventFragment = graphql`
  fragment AcademicCalendarEvent on DatoCmsAcademicCalendarEvent {
    __typename
    id: originalId
    eventName
    date
    multiDay
    endDate
    descriptor
  }
`
